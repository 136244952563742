import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";
import image4 from "./image4.jpg";
import image5 from "./image5.jpg";
import image6 from "./image6.jpg";
import image7 from "./image7.jpg";
import image8 from "./image8.jpg";
import image9 from "./image9.jpg";
import image10 from "./image10.jpg";
import image11 from "./image11.jpg";
import image12 from "./image12.jpg";

const images = [
  {
    src: image1,
    alt: 'Description of image 1',
    // ...other image properties
  },
  {
    src: image2,
    alt: 'Description of image 2',
    // ...other image properties
  },
  {
    src: image3,
    alt: 'Description of image 3',
    // ...other image properties
  },
  {
    src: image4,
    alt: 'Description of image 4',
    // ...other image properties
  },
  {
    src: image5,
    alt: 'Description of image 5',
    // ...other image properties
  },
  {
    src: image6,
    alt: 'Description of image 6',
    // ...other image properties
  },
  {
    src: image7,
    alt: 'Description of image 7',
    // ...other image properties
  },
  {
    src: image8,
    alt: 'Description of image 8',
    // ...other image properties
  },
  {
    src: image9,
    alt: 'Description of image 9',
    // ...other image properties
  },
  {
    src: image10,
    alt: 'Description of image 10',
    // ...other image properties
  },
  {
    src: image11,
    alt: 'Description of image 11',
    // ...other image properties
  },
  {
    src: image12,
    alt: 'Description of image 12',
    // ...other image properties
  },
  // ...more images
];


export default images;