import React from 'react';
import '../styles/AboutUs.css';

const AboutUs = () => {
  return (
    <section className="about-us">
      <div className="container">
        <h2>About Us</h2>
        <p>D-MAGIC AUTO SPA AND DETAILING is dedicated to providing top-notch auto spa and detailing services. Our team of professionals uses the latest techniques and products to ensure your vehicle looks its best. We pride ourselves on our attention to detail and our commitment to customer satisfaction.</p>
        <p>With over 5 years of experience, our mission has been to deliver exceptional service and unparalleled results for every client. Whether it's a simple wash or a complete detailing, we strive to exceed expectations and make your vehicle shine.</p>
        <h2>Tropical Vibe Tints and Wraps</h2>
        <p>Tropical Vibe Tints and Wraps brings a fresh and vibrant approach to vehicle customization. Specializing in high-quality tints and wraps, our services are designed to give your vehicle a unique and stylish look. Our team combines creativity with technical expertise to provide exceptional results that not only enhance aesthetics but also offer practical benefits like UV protection and improved privacy.</p>
        <p>We are committed to using premium materials and the latest techniques to ensure a perfect finish every time. Whether you're looking for a subtle change or a bold statement, Tropical Vibe Tints and Wraps has you covered.</p>
      </div>
    </section>
  );
};

export default AboutUs;
